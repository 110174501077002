import {
  useFetchActionObj,
  useSendAction,
} from '@laminar-product/client-commons-core/hooks';
import {
  addPromotionToPrice,
  editPriceFromPlan,
  getPlanPrices,
  removePromotionFromPrice,
} from 'actions/plans';
import { useCallback, useEffect, useMemo } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { Plan, PlanPrice } from 'types/plan';
import { captureError, captureErrorAndNotify } from 'utils/captureError';
import { errorNotify } from 'utils/errorNotify';
import notification from 'utils/notification';

const usePricing = (planUuid: string, selectedPriceUuid?: string) => {
  const { setValue, control } = useFormContext<Plan>();
  const formPrices = useWatch<PlanPrice[]>({ name: 'prices', control });

  const updatePrice = (price: PlanPrice) =>
    setValue(
      'prices',
      formPrices?.map((p) => (p.uuid === price.uuid ? price : p))
    );

  const {
    data: prices,
    isLoading: isFetching,
    refresh: refreshPrices,
  } = useFetchActionObj<PlanPrice[]>(
    useCallback(() => getPlanPrices(planUuid), [planUuid]),
    useMemo(
      () => ({
        onDone: (prices) => setValue('prices', prices),
      }),
      [setValue]
    )
  );

  const [assignPromotion, isAssigningPromotion] = useSendAction<
    PlanPrice,
    { promotionUuid: string; priceUuid: string }
  >(addPromotionToPrice, {
    onDone: updatePrice,
    onError: (error: any) => {
      errorNotify(error);
      captureError(error);
    },
  });

  const [removePromotion, isRemovingPromotion] = useSendAction<
    PlanPrice,
    { promotionUuid: string; priceUuid: string }
  >(removePromotionFromPrice, {
    onDone: refreshPrices,
  });

  const savePricing = async (form: PlanPrice) => {
    const payload = {
      distributionChannels: form?.distributionChannels?.map(String) || [],
    };
    const priceUuid = selectedPriceUuid || '';
    try {
      const updatedPrice = await editPriceFromPlan({
        planUuid,
        priceUuid,
        ...payload,
      });

      const updatedPrices = formPrices?.map((price) =>
        price.uuid === updatedPrice.uuid ? { ...price, ...updatedPrice } : price
      );
      setValue('prices', updatedPrices);
      notification.success({
        title: 'Success',
        description: 'Price has been successfully updated!',
      });
    } catch (error) {
      console.error('Error while saving pricing:', error);
      captureErrorAndNotify(error);
    }
  };

  useEffect(() => {
    if (prices) {
      setValue('prices', prices);
    }
  }, [prices, setValue]);

  return {
    savePricing,
    removePromotion,
    assignPromotion,
    isLoadingData: isFetching || isAssigningPromotion || isRemovingPromotion,
    isLoadingPricing: isFetching,
  };
};

export default usePricing;

//drag end - send request to api to update order of items
// setDataSource - update state of items in form state
// items - list of items uuids to be sorted
