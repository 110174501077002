import { useCallback, useEffect, useMemo, useState } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import {
  useFetchActionObj,
  useSendActionObj,
} from '@laminar-product/client-commons-core/hooks';
import { getAllPlans, orderPlans } from 'actions/plans';
import { Modal } from 'antd';
import Button from 'components/Button';
import ErrorIndicator from 'components/ErrorIndicator';
import PageContainer from 'components/PageContainer';
import PageHeader from 'components/PageHeader';
import Search from 'components/Search';
import notification from 'utils/notification';
import RegionsMenu from 'components/RegionsMenu';
import { Region } from 'types';
import { usePermission } from 'hooks/permissionsHook';
import { Permission } from 'utils/constants';
import { isAxiosError } from 'axios';
import styles from '../index.module.scss';
import PlanModal from '../components/PlanModal';
import PlanTable from './PlanTable';

const ProductList = () => {
  const [query, setQuery] = useState<string | undefined>();
  const [selectedRegion, setSelectedRegion] = useState<Region>();
  const [displayPlanModal, setDisplayPlanModal] = useState<boolean>(false);
  const { data, isLoading, error } = useFetchActionObj(
    useCallback(
      () =>
        getAllPlans({
          name: query,
          regionUuid: selectedRegion?.uuid,
        }),
      [query, selectedRegion?.uuid]
    ),
    useMemo(
      () => ({
        onError: () => {
          notification.error({
            title: "Couldn't load plan list",
            description: 'Refresh the page or contact support.',
          });
        },
      }),
      []
    )
  );

  const { sendAction, error: orderPlansError } = useSendActionObj(
    (planUuids: string[]) => {
      if (!selectedRegion) return Promise.reject();
      return orderPlans({
        regionUuid: selectedRegion?.uuid,
        planUuids,
      });
    }
  );

  useEffect(() => {
    if (orderPlansError) {
      const errorCode = isAxiosError(orderPlansError)
        ? orderPlansError?.response?.data?.code
        : 'unknown';
      notification.error({
        title: "Couldn't order plans",
        description: `Refresh the page or contact support. The error code: ${errorCode}`,
      });
    }
  }, [orderPlansError]);

  const { canUse } = usePermission();

  const handleSearch = useCallback((query: string) => {
    setQuery(query);
  }, []);

  return (
    <>
      <PageContainer>
        <PageHeader
          title="Plans"
          extra={[
            <Search
              onSearch={handleSearch}
              key="search"
              placeholder="Search plan by name"
            />,
            ...(canUse([Permission.SAVE_PLAN])
              ? [
                  <Button
                    type="primary"
                    icon={<PlusOutlined />}
                    onClick={() => setDisplayPlanModal(true)}
                  >
                    Create new plan
                  </Button>,
                ]
              : []),
          ]}
        />
        <ErrorIndicator error={error} />

        <div className={styles.wrapper}>
          <RegionsMenu
            defaultKey={selectedRegion?.uuid}
            onRegionSelect={setSelectedRegion}
          />
          <PlanTable
            plans={data?.data}
            loading={isLoading}
            onDragEnd={sendAction}
          />
        </div>
        {displayPlanModal && (
          <Modal
            visible={displayPlanModal}
            onCancel={() => setDisplayPlanModal(false)}
            destroyOnClose
            footer={null}
            title={'Add new plan'}
          >
            <PlanModal />
          </Modal>
        )}
      </PageContainer>
    </>
  );
};

export default ProductList;
