import React, { ReactNode } from 'react';
import { AxiosError } from 'axios';

interface ErrorIndicatorProps {
  error: Error | AxiosError | undefined;
  showResponseMessage?: boolean;
}

const determineIfAxiosError = (
  error: Error | AxiosError
): error is AxiosError => {
  return (error as AxiosError).response !== undefined;
};

export const ErrorMessage = ({ children }: { children: ReactNode }) => {
  return <div style={{ color: 'red' }}>{children}</div>;
};

// Was about to name it ErrorMessage but react-hook-form also uses that name
const ErrorIndicator = ({
  error,
  showResponseMessage = false,
}: ErrorIndicatorProps) => {
  if (!error) {
    return null;
  }

  if (determineIfAxiosError(error)) {
    const errorMessage = (error.response?.data as { message?: string }).message;
    const displayMessage = showResponseMessage && errorMessage != null;

    if (error.response?.status === 404)
      return (
        <ErrorMessage>
          {displayMessage
            ? errorMessage
            : "We're sorry, but we did not found what you're looking for."}
        </ErrorMessage>
      );

    if (error.response?.status === 400)
      return (
        <ErrorMessage>
          {displayMessage
            ? errorMessage
            : 'There was an error during the request, please check provided values and try again.'}
        </ErrorMessage>
      );

    return (
      <ErrorMessage>
        {displayMessage ? errorMessage : 'Something went wrong.'}
      </ErrorMessage>
    );
  }

  return <ErrorMessage>{error.message}</ErrorMessage>;
};

export default ErrorIndicator;
