import React, { useCallback, useState } from 'react';
import FileSelect from 'components/FileSelect';
import { initImageUpload, uploadImageToS3 } from 'actions/images';
import { Progress, Space } from 'antd';
import { attachImage } from 'actions/assets';
import ErrorIndicator from 'components/ErrorIndicator';
import { useSendAction } from '@laminar-product/client-commons-core/hooks';
import { useAbortSignal } from 'utils/useCancelToken';
import { notifyAboutRepublishRequirement } from 'utils/assetsMessage';
import { ImageType } from '@laminar-product/client-commons-core/core';

const uploadImage = async ({
  file,
  type,
  signal,
  onProgress,
  assetId,
}: {
  file: File;
  type: ImageType;
  signal: AbortSignal;
  onProgress: (percentage: number) => void;
  assetId: number;
}) => {
  const { signedUrl, id } = await initImageUpload({
    name: file.name,
    type,
    signal,
  });

  await uploadImageToS3({
    file,
    signedUrl: signedUrl.url,
    onProgress,
    signal,
    params: signedUrl.fields,
  });

  await attachImage({ id: assetId, imageId: id, signal });
};

const ImageUpload = ({
  type,
  refresh,
  assetId,
}: {
  type: ImageType;
  refresh: () => void;
  assetId: number;
}) => {
  const [progress, setProgress] = useState<number>();
  const abortSignal = useAbortSignal();

  const action = useCallback(
    (file: File) =>
      uploadImage({
        file,
        type,
        onProgress: setProgress,
        assetId,
        signal: abortSignal,
      }),
    [assetId, abortSignal, type]
  );

  const [startUpload, isLoading, , , error] = useSendAction<void, File>(
    action,
    {
      onDone: () => {
        refresh();
        notifyAboutRepublishRequirement();
      },
    }
  );

  return (
    <Space direction="vertical">
      <FileSelect onSelectFile={startUpload} loading={isLoading}>
        Upload image
      </FileSelect>
      <ErrorIndicator error={error} />
      {progress && <Progress percent={Math.round(progress * 100)} />}
    </Space>
  );
};

export default ImageUpload;
