import { useCallback, useEffect, useState } from 'react';
import FormContainer from 'components/FormContainer';
import { Table } from 'antd';
import PromotionRow from 'pages/Plans/PlanDetails/components/Pricing/PromotionRow';
import { ReactComponent as TrashIcon } from 'assets/icons/trash-icon.svg';
import { EditOutlined } from '@ant-design/icons';
import PromotionsChooser from 'components/PromotionsChooser';
import cn from 'classnames';
import { PaymentGateway, Plan, PlanOptions, PlanPrice } from 'types/plan';
import {
  useFetchAction,
  useSendActionObj,
} from '@laminar-product/client-commons-core/hooks';
import { getSubscriptionOptions, orderPlanPrices } from 'actions/plans';
import { useFormContext, useWatch } from 'react-hook-form';
import { shouldProvideBillingAmount } from 'utils/pricing';
import { Price } from 'types';
import { DevicePlatform } from 'types/devices';
import DraggableRow, {
  DragHandle,
} from 'components/DraggableTable/DraggableRow';
import DraggableTable from 'components/DraggableTable';
import { isAxiosError } from 'axios';
import notification from 'utils/notification';
import PricingModal from '../PricingModal';
import styles from './index.module.scss';
import usePricing from './usePricing';

const { Column } = Table;
export interface PricingPageProps {
  options?: PlanOptions;
}

const defaultPlatform: DevicePlatform[] = [
  DevicePlatform.WEB,
  DevicePlatform.TV,
  DevicePlatform.MOBILE,
];

const getFormattedAmount = (amount: number) =>
  Number.isInteger(amount / 100) ? amount / 100 + '.00' : amount / 100;

const Pricing = ({ planUuid }: { planUuid: string }) => {
  const { setValue, control } = useFormContext<Plan>();
  const formPrices = useWatch<PlanPrice[]>({ name: 'prices', control });
  const [activePricingUuid, setActivePricingUuid] = useState<string>();
  const [pricingModal, setPricingModal] = useState<boolean>(false);
  const [selectedPrice, setSelectedPrice] = useState<PlanPrice | null>(null);
  const [options] = useFetchAction<PlanOptions>(getSubscriptionOptions);
  const {
    isLoadingData,
    isLoadingPricing,
    removePromotion,
    assignPromotion,
    savePricing,
  } = usePricing(planUuid, selectedPrice?.uuid);

  const handleSavePricing = useCallback(
    async (price: PlanPrice) => {
      try {
        await savePricing(price);
      } finally {
        togglePricingModal(false);
      }
    },
    [savePricing]
  );

  const togglePricingModal = (
    open: boolean,
    price: PlanPrice | null = null
  ) => {
    setSelectedPrice(price);
    setPricingModal(open);
  };

  const { sendAction: onDragEnd, error: orderPricesError } = useSendActionObj(
    (priceUuids: string[]) => {
      return orderPlanPrices({
        planUuid: planUuid,
        priceUuids,
      });
    }
  );

  const setDataSource: React.Dispatch<React.SetStateAction<PlanPrice[]>> = (
    fnOrArray
  ) => {
    setValue(
      'prices',
      typeof fnOrArray === 'function' ? fnOrArray(formPrices ?? []) : fnOrArray
    );
  };

  useEffect(() => {
    if (orderPricesError) {
      const errorCode = isAxiosError(orderPricesError)
        ? orderPricesError?.response?.data?.code
        : 'unknown';
      notification.error({
        title: "Couldn't order plans",
        description: `Refresh the page or contact support. The error code: ${errorCode}`,
      });
    }
  }, [orderPricesError]);

  return (
    <FormContainer>
      <DraggableTable
        onDragEnd={onDragEnd}
        setDataSource={setDataSource}
        items={formPrices?.map((p) => p.uuid) || []}
      >
        <Table
          loading={isLoadingData}
          dataSource={formPrices}
          pagination={false}
          components={{ body: { row: DraggableRow } }}
          rowKey={(record) => record.uuid}
          expandable={{
            expandIconColumnIndex: 1,
            expandRowByClick: true,
            expandedRowRender: (record) =>
              record.promotions.map((p) => (
                <PromotionRow
                  key={p.uuid}
                  promotion={p}
                  onRemove={(promotionUuid) =>
                    removePromotion({ promotionUuid, priceUuid: record.uuid })
                  }
                />
              )),
            rowExpandable: (record) => !!record.promotions?.length,
          }}
        >
          <Column key="sort" width={80} render={() => <DragHandle />} />

          <Column
            title="Billing frequency"
            key="interval"
            dataIndex="interval"
            render={(interval, row: any) =>
              `${row.intervalMultiplier ?? 1} ${interval}`
            }
          />
          <Column
            title="Amount"
            key="amount"
            render={(_, row: Price & { gateway: PaymentGateway }) =>
              !shouldProvideBillingAmount(row.gateway)
                ? '-'
                : `${getFormattedAmount(
                    row.amount
                  )} ${row.currency?.toUpperCase()}`
            }
          />
          <Column title="Payment Channel" key="gateway" dataIndex="gateway" />
          <Column
            title="Sales Channel"
            key="distributionChannels"
            dataIndex="distributionChannels"
            render={(distributionChannels) =>
              distributionChannels?.length
                ? distributionChannels.join(', ')
                : '-'
            }
          />
          <Column
            title="External Payment ID"
            key="externalProductId"
            dataIndex="externalProductId"
          />
          <Column
            key="actions"
            dataIndex="uuid"
            render={(uuid: string, row: PlanPrice) => (
              <div
                onClick={(e) => e.stopPropagation()}
                className={styles.actionsCel}
              >
                <span
                  className={cn(styles.assignPromotionAction, [
                    {
                      [styles.disabledAssignPromoLabel]:
                        activePricingUuid && activePricingUuid !== uuid,
                    },
                  ])}
                  onClick={() => {
                    setActivePricingUuid(uuid);
                  }}
                >
                  + Assign promotion
                </span>
                <span
                  className={styles.pointer}
                  onClick={() => togglePricingModal(true, row)}
                >
                  <EditOutlined />
                </span>
              </div>
            )}
          />
        </Table>
      </DraggableTable>
      {pricingModal && selectedPrice && (
        <PricingModal
          isDisabledChangePaymentChannel={true}
          paymentGateway={selectedPrice.gateway}
          intervalMultiplier={selectedPrice.intervalMultiplier ?? 1}
          interval={selectedPrice.interval}
          currency={selectedPrice.currency}
          amount={getFormattedAmount(selectedPrice.amount)}
          isDisabledChangeBillingFrequency={true}
          isDisabledChangeBillingValue={true}
          isDisabledChangeAmount={true}
          isDisabledCurrency={true}
          options={options}
          open={pricingModal}
          onClose={() => togglePricingModal(false)}
          platforms={defaultPlatform}
          selectedPlatform={selectedPrice.distributionChannels}
          addPrice={handleSavePricing}
          isLoading={isLoadingPricing}
        />
      )}
      {!!activePricingUuid?.length && (
        <div className={styles.searchWrapper}>
          <PromotionsChooser
            prices={formPrices}
            placeholder="Assign promotion"
            onChoose={(promotion) => {
              if (activePricingUuid)
                assignPromotion({
                  promotionUuid: promotion.uuid,
                  priceUuid: activePricingUuid,
                });
              setActivePricingUuid(undefined);
            }}
            gatewayType={
              formPrices?.find((price) => price.uuid === activePricingUuid)
                ?.gateway
            }
            activePricingUuid={activePricingUuid}
          />
          <span
            onClick={() => setActivePricingUuid(undefined)}
            className={styles.trash}
          >
            <TrashIcon />
          </span>
        </div>
      )}
    </FormContainer>
  );
};

export default Pricing;
