import { DndContext, DragEndEvent } from '@dnd-kit/core';
import {
  SortableContext,
  arrayMove,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { ReactElement } from 'react';
import { Table } from 'antd';

type DraggableTableProps<T extends Record<string, any>> = {
  children: ReactElement<typeof Table>;
  items: string[];
  onDragEnd: (sortedIds: string[]) => void;
  setDataSource: React.Dispatch<React.SetStateAction<T[]>>;
  idKey?: keyof T;
};

const DraggableTable = <T extends Record<string, any>>({
  children,
  items,
  onDragEnd,
  setDataSource,
  idKey = 'uuid',
}: DraggableTableProps<T>) => {
  const handleTableDragEnd = ({ active, over }: DragEndEvent) => {
    if (active.id !== over?.id) {
      setDataSource((prevState) => {
        const activeIndex = prevState.findIndex(
          (record) => record[idKey] === active?.id
        );
        const overIndex = prevState.findIndex(
          (record) => record[idKey] === over?.id
        );
        const sortedArray = arrayMove(prevState, activeIndex, overIndex);

        onDragEnd(sortedArray.map((p) => p[idKey]));
        return sortedArray;
      });
    }
  };

  return (
    <DndContext
      modifiers={[restrictToVerticalAxis]}
      onDragEnd={handleTableDragEnd}
    >
      <SortableContext items={items} strategy={verticalListSortingStrategy}>
        {children}
      </SortableContext>
    </DndContext>
  );
};

export default DraggableTable;
