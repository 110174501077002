import { Table, Tag } from 'antd';
import useInterestsTableFilter from 'hooks/useInterestsTableFilter';
import { useHistory } from 'react-router-dom';
import _capitalize from 'lodash/capitalize';
import StringList from 'components/StringList';
import formatLabel from 'utils/formatLabel';
import { Plan, Quality } from 'types/plan';
import { Paths } from 'types/paths';
import { useEffect, useState } from 'react';
import DraggableRow, {
  DragHandle,
} from 'components/DraggableTable/DraggableRow';
import DraggableTable from 'components/DraggableTable';
import styles from '../index.module.scss';

const { Column } = Table;

const getQualityLabels = (qualityValues: Quality[]) => {
  if (!qualityValues) return;
  const qualities = {
    '1080': 'HD',
    '2160': '4K',
    '720': undefined,
    '540': 'SD',
  };
  return qualityValues.map((q) => qualities[q]);
};

const PlanTable = ({
  plans,
  loading,
  onDragEnd,
}: {
  plans?: Plan[];
  loading?: boolean;
  onDragEnd: (planUuids: string[]) => void;
}) => {
  const history = useHistory();
  const [dataSource, setDataSource] = useState<Plan[]>(plans || []);
  const { filters } = useInterestsTableFilter<Plan>({
    onFilter: (search, plan) =>
      !search || !!plan.interests?.includes(search as string),
  });

  useEffect(() => {
    setDataSource(plans || []);
  }, [plans]);
  if (!dataSource) return null;

  return (
    <DraggableTable
      setDataSource={setDataSource}
      onDragEnd={onDragEnd}
      items={dataSource.map((p) => p.uuid)}
    >
      <Table
        className={styles.planTable}
        dataSource={dataSource}
        rowKey="uuid"
        loading={loading}
        components={{ body: { row: DraggableRow } }}
        onRow={(plan: Plan) => ({
          onClick: () => history.push(`${Paths.PLANS}/${plan.uuid}`),
        })}
        rowClassName="clickable-row"
        pagination={false}
      >
        <Column key="sort" width={80} render={() => <DragHandle />} />
        <Column
          title="Plan name"
          dataIndex="administrativeName"
          key="administrativeName"
        />
        <Column
          title="Interests"
          dataIndex="interests"
          key="interests"
          render={(interests: string[]) => (
            <StringList items={interests} maxShowCount={3} />
          )}
          {...filters}
        />
        <Column
          title="Platforms"
          dataIndex="platforms"
          key="platforms"
          render={(platforms) =>
            platforms?.map((p: string) => _capitalize(p)).join(', ')
          }
        />
        <Column
          title="Quality"
          dataIndex="quality"
          key="quality"
          render={(quality) => getQualityLabels(quality)?.join(', ')}
        />
        <Column
          title="Travel to"
          dataIndex="availableRegions"
          key="availableRegions"
          render={(_, plan: Plan) => {
            const travelRegions = plan.availableRegions?.map(
              (region) => region.name
            );

            if (!travelRegions || !travelRegions.length) return '-';

            return <StringList items={travelRegions} />;
          }}
        />
        <Column
          title="Status"
          dataIndex="status"
          key="status"
          render={(status) => (
            <Tag color={status === 'PUBLISHED' ? 'green' : 'red'}>
              {formatLabel(status)}
            </Tag>
          )}
        />
      </Table>
    </DraggableTable>
  );
};

export default PlanTable;
