import { CancelToken } from 'axios';
import {
  Asset,
  AssetAccessType,
  AssetChildren,
  AssetCreateForm,
  AssetMainDetails,
  AssetMetadata,
  AssetStatus,
  BreadCrumbsPath,
  Content,
  Image,
} from 'types';
import { PAGINATION_SIZE, Pagination, pageToSkip } from 'utils/pagination';
import { AssetTypesResponse } from 'types/asset';
import { AssetType } from '@laminar-product/client-commons-core/core';
import api from './api';

export const updateAssetMainDetails = async ({
  assetId,
  data,
}: {
  assetId?: number;
  data: AssetMainDetails;
}): Promise<void> => {
  return await api().put(`/asset/${assetId}`, data);
};

export interface GetAssetsProps {
  regionUuids?: string[];
  assetUuids?: string[];
  page?: number;
  query?: string;
  hasParent?: boolean;
  statuses?: AssetStatus[];
  limit?: number;
  types?: AssetType[];
  accessTypes?: AssetAccessType[];
}

export const getAssets = async ({
  regionUuids,
  page,
  assetUuids,
  query,
  hasParent,
  statuses,
  limit,
  types,
  accessTypes,
}: GetAssetsProps): Promise<Asset[]> => {
  const name = query?.length ? query : undefined;
  const { data } = await api().get(`/asset/filter`, {
    params: {
      name,
      skip: pageToSkip(page, limit),
      limit: limit || PAGINATION_SIZE,
      assetUuids,
      regionUuids,
      statuses,
      hasParent,
      types,
      accessTypes,
    },
  });

  return data;
};

export const getAssetsPagination = async ({
  regionUuids,
  page,
  assetUuids,
  query,
  hasParent,
  statuses,
  limit,
  types,
  accessTypes,
}: GetAssetsProps): Promise<Pagination<Asset>> => {
  const name = query?.length ? query : undefined;
  const { data } = await api().get(`/asset`, {
    params: {
      name,
      skip: pageToSkip(page),
      limit: limit || PAGINATION_SIZE,
      assetUuids,
      regionUuids,
      statuses,
      hasParent,
      types,
      accessTypes,
    },
  });

  return data;
};

export const getAssetsChildren = async ({
  cancelToken,
  parentId,
  accessType,
}: {
  cancelToken?: CancelToken;
  accessType?: AssetAccessType;
  parentId: string | number;
}): Promise<AssetChildren[]> => {
  const { data } = await api().get(`/asset/${parentId}/children/search`, {
    params: { accessType },
    cancelToken,
  });

  return data;
};

export const changeAssetAccessType = async ({
  id,
  accessType,
}: {
  id: number;
  accessType: AssetAccessType;
}) => api().patch(`/asset/${id}/access`, { accessType });

export const getAssetsForParent = async ({
  cancelToken,
  parentId,
}: {
  cancelToken?: CancelToken;
  parentId: string | number;
}): Promise<Asset[]> => {
  const { data } = await api().get(`/asset/parent/${parentId}`, {
    cancelToken,
  });

  return data;
};

export const getAssetsForParentWithOrder = async ({
  cancelToken,
  parentId,
}: {
  cancelToken?: CancelToken;
  parentId: string | number;
}): Promise<Asset[]> => {
  const { data } = await api().get(`/asset/${parentId}/children`, {
    cancelToken,
  });

  return data.map((asset: Asset) => ({ ...asset, children: undefined }));
};

export const changeAssetChildrenOrder = async ({
  id,
  order,
}: {
  id: string | number;
  order: number[];
}) => api().put(`/asset/${id}/order`, order);

interface CreateAssetParameters {
  cancelToken?: CancelToken;
  form: AssetCreateForm;
}

export const createAsset = async (
  params: CreateAssetParameters
): Promise<Asset> => {
  const { data } = await api().post<Asset>(`/asset`, params.form, {
    cancelToken: params?.cancelToken,
  });

  return data;
};

export const getAssetByUuid = async (uuid: string): Promise<Asset> => {
  const { data } = await api().get(`/asset/uuid/${uuid}`);

  return data;
};

export const getAssetById = async (id: number): Promise<Asset> => {
  const { data } = await api().get(`/asset/${id}`);

  return data;
};

interface SearchForAssetParameters {
  query: string;
  shouldHaveFiles?: boolean;
  cancelToken?: CancelToken;
  regionIds?: number[];
  uuids?: string[];
}

interface SearchForAssetParentParameters {
  query: string;
  cancelToken?: CancelToken;
  limit?: number;
}

export const searchForAsset = async ({
  query,
  shouldHaveFiles,
  regionIds,
  uuids,
}: SearchForAssetParameters): Promise<Asset[]> => {
  let content = undefined;

  if (shouldHaveFiles === true) content = 'assigned';
  else if (shouldHaveFiles === false) content = 'unassigned';

  const { data } = await api().get(`/asset/search`, {
    params: {
      query,
      content,
      regionIds,
      uuids,
    },
  });

  return data?.data;
};

export const searchForAssetParent = async ({
  query,
  cancelToken,
  limit,
}: SearchForAssetParentParameters): Promise<Asset[]> => {
  const { data } = await api().get(`/asset/search/parent`, {
    params: { query, limit },
    cancelToken,
  });

  return data?.data;
};

export const breadCrumbsPath = async ({
  currentAssetId,
}: {
  currentAssetId?: number;
}): Promise<BreadCrumbsPath[]> => {
  const { data } = await api().get(`/asset/${currentAssetId}/ancestors`, {
    params: { currentAssetId },
  });

  return data;
};

export const changeParent = async ({
  id,
  parentId,
  cancelToken,
}: {
  id: string | number;
  parentId: string | number;
  cancelToken?: CancelToken;
}): Promise<void> => {
  await api().put(
    `/asset/${id}/parent`,
    { parentId },
    {
      cancelToken,
    }
  );
};

export const deleteParent = async ({
  id,
  cancelToken,
}: {
  id: string | number;
  cancelToken?: CancelToken;
}): Promise<void> => {
  await api().delete(`/asset/${id}/parent`, { cancelToken });
};

export const getAssetImages = async ({
  id,
}: {
  id: number;
}): Promise<Image[]> => {
  const { data } = await api().get(`/asset/${id}/image`);

  return data;
};

export const attachImage = async ({
  id,
  imageId,
  signal,
}: {
  id: string | number;
  imageId: string | number;
  signal?: AbortSignal;
}) => api().put(`/asset/${id}/image`, { id: imageId }, { signal });

export const detachImage = async ({
  id,
  imageId,
  cancelToken,
}: {
  id: string | number;
  imageId: string | number;
  cancelToken?: CancelToken;
}) => api().delete(`/asset/${id}/image/${imageId}`, { cancelToken });

export const updateMetadata = async (
  assetUuid: string,
  metadata: AssetMetadata
): Promise<AssetMetadata> => {
  const { selectedLanguage, ...data } = metadata;
  await api().post(`/asset/${assetUuid}/translation`, {
    language: selectedLanguage,
    metadata: data,
  });

  return metadata;
};

export const addMetadata = async (
  uuid: string,
  metadata: AssetMetadata
): Promise<AssetMetadata> => {
  const { selectedLanguage, ...data } = metadata;
  await api().post(`/asset/${uuid}/translation`, {
    language: selectedLanguage,
    metadata: data,
  });

  return metadata;
};

export const getAssetMetadata = async (assetUuid: string) => {
  const { data } = await api().get(`/asset/${assetUuid}/translation`);

  return data;
};

export const deleteAssetMetadata = async (assetUuid: string, lang: string) => {
  const { data } = await api().delete(
    `/asset/${assetUuid}/translation?language=${lang}`
  );

  return data;
};

export const getAssetContents = async ({
  cancelToken,
  id,
}: {
  id: string | number;
  cancelToken?: CancelToken;
}): Promise<Content[]> => {
  const { data } = await api().get(`/asset/${id}/content`, {
    cancelToken,
  });

  return data as Content[];
};

export const getAssetTypes = async (): Promise<AssetType[]> => {
  const { data } = await api().get<AssetTypesResponse[]>('/asset/type');

  return data.map(({ name }) => name);
};
