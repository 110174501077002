import type { SyntheticListenerMap } from '@dnd-kit/core/dist/hooks/utilities';
import { createContext } from 'react';

export interface RowContextProps {
  setActivatorNodeRef?: (element: HTMLElement | null) => void;
  listeners?: SyntheticListenerMap;
}

const RowContext = createContext<RowContextProps>({});

export default RowContext;
