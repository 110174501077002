import { checkExcludedSentryErrors } from '@laminar-product/client-commons-core/core';
import { captureException } from '@sentry/react';
import { AxiosError, isAxiosError } from 'axios';
import { FirebaseError } from 'firebase/app';
import notification from './notification';

export type CaptureErrorType = AxiosError | FirebaseError | Error;

export const getAxiosErrorCode = (error?: Error) => {
  if (isAxiosError(error)) {
    return error?.response?.data?.code as string;
  }
  return undefined;
};

export const captureError = (error: CaptureErrorType) => {
  if (!error) return;
  console.error(error);

  if (import.meta.env.NODE_ENV === 'development') return;

  if ((error as AxiosError).isAxiosError === true) {
    const errorToCheck = error as AxiosError;
    const errorStatus = errorToCheck?.response?.status;
    const errorCode = (errorToCheck?.response?.data as { code: string })?.code;

    if (checkExcludedSentryErrors(errorStatus)) {
      return;
    }

    captureException(error, { extra: { errorCode } });
    return;
  }

  captureException(error);
};

export const fallbackErrorMessage =
  'There was an error during the request, please check provided values or try again later.';

export const captureErrorAndNotify = (
  error: any,
  customDescription?: string
) => {
  captureError(error);

  notification.error({
    title: 'Failure',
    description:
      customDescription ||
      error?.response?.data?.details ||
      fallbackErrorMessage,
  });
};
