import { useSendActionObj } from '@laminar-product/client-commons-core/hooks';
import {
  deletePlatformPartner,
  publishPlatformPartner,
  unpublishPlatformPartner,
  updatePlatformPartner,
} from 'actions/platformPartners';
import notification, { NotificationProps } from 'utils/notification';
import { PlatformPartner } from 'types/platformPartners';
import { useHistory } from 'react-router-dom';
import { useState } from 'react';
import { getAxiosErrorCode } from 'utils/captureError';

const onError = (error?: Error) => {
  const errorCode = getAxiosErrorCode(error);

  let description = 'Something went wrong. Please try again!';
  switch (errorCode) {
    case 'platform_partner/unpublish_not_published':
      description = "Cannot unpublish partner that isn't published";
      break;
    case 'platform_partner/cannot_be_deleted':
      description =
        'Cannot delete the partner - check if partner is unpublished';
      break;
    case 'platform_partner/publish_no_plans':
      description = 'Cannot publish the partner - there are no plans assigned';
      break;
    default:
      break;
  }
  let notificationData: NotificationProps = {
    title: 'Error',
    description,
  };

  notification.error(notificationData);
};

interface UsePartnerActionsProps {
  refreshPartner?: () => void;
  refreshPartnersList?: () => void;
}

const usePartnerActions = (props?: UsePartnerActionsProps) => {
  const { push } = useHistory();
  const { refreshPartner, refreshPartnersList } = props || {};
  const [isDeletePartnerModalVisible, toggleDeletePartnerModalVisibility] =
    useState(false);

  const { sendAction: savePartner, isLoading: isSaving } = useSendActionObj<
    PlatformPartner,
    { uuid: string; partner: Partial<PlatformPartner> }
  >(({ uuid, partner }) => updatePlatformPartner(uuid, partner), {
    onDone: (data) => {
      refreshPartner?.();
      notification.success({
        title: 'Success',
        description: `${data?.administrativeName} saved!`,
      });
    },
    onError,
  });

  const { sendAction: publishPartner, isLoading: isPublishing } =
    useSendActionObj<
      void,
      {
        uuid: string;
        platformPartner: Omit<PlatformPartner, 'uuid'>;
        shouldSave?: boolean;
      }
    >(
      async ({ uuid, platformPartner, shouldSave }) => {
        if (shouldSave) {
          await updatePlatformPartner(uuid, platformPartner);
        }
        return publishPlatformPartner(uuid).then(() => {
          refreshPartner?.();
          notification.success({
            title: 'Success',
            description: `${platformPartner.administrativeName} published!`,
          });
        });
      },
      {
        onError,
      }
    );

  const { sendAction: unpublishPartner, isLoading: isUnpublishing } =
    useSendActionObj<void, { uuid: string; partnerName: string }>(
      ({ uuid, partnerName }) =>
        unpublishPlatformPartner(uuid).then(() => {
          refreshPartner?.();
          notification.success({
            title: 'Success',
            description: `${partnerName} unpublished!`,
          });
        }),
      {
        onError,
      }
    );

  const { sendAction: deletePartner, isLoading: isDeleting } = useSendActionObj<
    void,
    { uuid: string; partnerName: string }
  >(
    ({ uuid, partnerName }) => {
      return deletePlatformPartner(uuid).then(() => {
        notification.success({
          title: 'Success',
          description: `${partnerName} deleted!`,
        });
        toggleDeletePartnerModalVisibility(false);
        push('/partners');
        refreshPartnersList?.();
      });
    },
    {
      onError: (error) => {
        toggleDeletePartnerModalVisibility(false);
        onError(error);
      },
    }
  );

  return {
    savePartner,
    isSaving,
    deletePartner,
    isDeleting,
    publishPartner,
    isPublishing,
    unpublishPartner,
    isUnpublishing,
    toggleDeletePartnerModal: toggleDeletePartnerModalVisibility,
    isDeletePartnerModalVisible,
  };
};

export default usePartnerActions;
