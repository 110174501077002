import { useEffect, useState } from 'react';

export const useAbortSignal = (): AbortSignal => {
  const [abortController, setAbortController] = useState(new AbortController());

  useEffect(() => {
    const controller = new AbortController();
    setAbortController(controller);

    return () => controller.abort();
  }, []);

  return abortController.signal;
};
