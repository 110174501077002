import { useSendActionObj } from '@laminar-product/client-commons-core/hooks';
import {
  removeContentDistributionConfig,
  updateContentDistributionConfig,
} from 'actions/contentDistribution';
import { AxiosError } from 'axios';
import {
  ContentDistributionConfig,
  ContentDistributionConfigDetails,
} from 'types/contentDistribution';
import { captureError, getAxiosErrorCode } from 'utils/captureError';
import notification, { NotificationProps } from 'utils/notification';
import {
  DISTRIBUTION_ASSETS_NOT_IN_REGION_ERROR,
  DISTRIBUTOR_ALREADY_EXISTS_ERROR_CODE,
} from '../utils';

interface UpdateContentDistributionConfigProps {
  config: ContentDistributionConfig;
  id: string;
}
interface UseRemoveContentDistributionConfigProps {
  onRemoveSuccess: () => void;
  refreshConfig?: () => void;
  config?: ContentDistributionConfigDetails;
}

const onErrorNotify = (error?: AxiosError) => {
  const errorCode = getAxiosErrorCode(error);

  let notificationData: NotificationProps = {
    title: 'Error',
    description: 'Something went wrong. Please try again!',
  };

  if (errorCode === DISTRIBUTOR_ALREADY_EXISTS_ERROR_CODE) {
    notificationData = {
      title: 'Error',
      description:
        'Content distribution with provided distributor ID already exists!',
    };
  }

  if (errorCode === DISTRIBUTION_ASSETS_NOT_IN_REGION_ERROR) {
    notificationData = {
      title: 'Error',
      description:
        'Content distribution contains assets that are not published in provided region!',
    };
  }

  notification.error(notificationData);
};

const useContentDistributionConfigActions = ({
  onRemoveSuccess,
  refreshConfig,
  config,
}: UseRemoveContentDistributionConfigProps) => {
  const handleRefresh = () => {
    if (refreshConfig) {
      refreshConfig();
    }
  };

  const { sendAction: removeConfig, isLoading: isRemoving } = useSendActionObj<
    void,
    string
  >(async (configUuid) => await removeContentDistributionConfig(configUuid), {
    onDone: onRemoveSuccess,
    onError: (error) => {
      onErrorNotify();
      captureError(error as any);
    },
  });

  const { sendAction: updateConfig, isLoading: isUpdating } = useSendActionObj<
    ContentDistributionConfig,
    UpdateContentDistributionConfigProps
  >(
    async ({ config, id }: UpdateContentDistributionConfigProps) =>
      await updateContentDistributionConfig({
        config,
        id,
      }),
    {
      onDone: () => {
        handleRefresh();

        notification.success({
          title: 'Success',
          description: `${config?.name} configuration saved!`,
        });
      },
      onError: (error) => onErrorNotify(error as any),
    }
  );

  return {
    removeConfig,
    isRemoving,
    updateConfig,
    isUpdating,
  };
};

export default useContentDistributionConfigActions;
